@use "../variables";
@use "../mixins";

.footer {
   color: variables.$color-neutral-high-base;
   background-color: variables.$color-neutral-low-base;
   padding: 3.5rem 0;

   &__container {
      flex-direction: column;
      display: flex;
      gap: 20px;
      align-items: center;

      @include mixins.md {
         flex-direction: row;
         justify-content: space-between;
      }
   }

   &__links {
      display: flex;
      gap: 20px;
   }
   .link__icon {
      overflow: hidden;
      height: 24px;
      width: 24px;
      text-indent: 9999px;
      display: flex;
   }

   [class^="i__"]::before {
      height: 24px;
      width: 24px;
      content: "";
      display: flex;
   }
   .i__linkedin::before {
      background: url("../../images/icons/light-linkedin.svg") no-repeat;
   }
   .i__github::before {
      background: url("../../images/icons/light-github.svg") no-repeat;
   }
}
