@use "../variables.scss";

.project-card {
   display: flex;
   flex-direction: column;
   gap: 24px;
   transition: .2s ease-in-out;
   &__image {
      aspect-ratio: 1 / 1 ;
      overflow: hidden;
      position: relative;
      img {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: center center;
         transition: .2s ease-in-out;
      }
      &:hover {
         img {
            transform: scale(0.98) translate(0.2%, 0.2%);

         }
      }
   }
   &__infos{
      color: variables.$color-neutral-low-base;
      transition: .2s ease-in-out;
      h3 {
         font-size: 20px;
         font-weight: 600;
         margin-bottom: 2px;
      }
      &:hover{
         color: variables.$color-neutral-low-medium;
      }
   }
}