@use "variables";
@use "typography";
@use "mixins";

* {
  box-sizing: border-box;
}

html,
body,
p {
  padding: 0;
  margin: 0;
}

body {
  font-family: variables.$font-stack;
  line-height: 180%;
}

a {
  text-decoration: none;
  transition: 0.15s ease-in-out;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

.link {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  gap: 4px;

  &__default,
  &__default:visited {
    color: variables.$color-neutral-low-medium;
    &::after {
      background-color: variables.$color-neutral-low-medium;
    }
  }

  &__primary,
  &__primary:visited {
    color: variables.$color-brand-primary-base;
    &::after {
      background-color: variables.$color-brand-primary-base;
    }
  }

  &__default,
  &__primary {
    position: relative;
    &::after {
      content: "";
      display: block;
      height: 2px;
      left: 0;
      bottom: -4px;
      position: absolute;
      transition: 0.15s ease-in-out;
      width: 0%;
    }
    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}

i.arrow-back {
  background: url("../images//icons/medium-arrow-left.svg") no-repeat;
  height: 24px;
  width: 24px;
  margin-left: -8px;
}

.container {
  width: 100%;
  padding: 0 20px;

  @include mixins.md {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mixins.lg {
    width: 960px;
  }

  @include mixins.xxl {
    width: 1336px;
    &__offsetLeft {
      grid-area: offsetLeft;
    }
    &__offsetRight {
      grid-area: offsetRight;
    }
    &__content {
      grid-area: content;
    }
    &__grid {
      display: grid;
      grid-template-areas: "offsetLeft content offsetRight";
      grid-template-columns: 1fr 10fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 24px;
    }
  }
}
