@use "../variables.scss";

.inverse {
      background-color: variables.$color-neutral-high-base;
      color: variables.$color-neutral-low-base;
}
.default {
      background-color: variables.$color-neutral-low-base;
      color: variables.$color-neutral-high-base;
}
