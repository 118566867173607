@use "../variables.scss";
@use "../mixins.scss";

.home {
   display: flex;
   flex-direction: column;
   height: 100vh;

   .container {
      height: 100%;
   }

   &__links {
      display: flex;
      flex-direction: column;
      gap: 20px;
      p {
         font-size: 1.5rem;
         font-weight: 300;
         @include mixins.sm {
            font-size: 1.5rem;
         }
      }
   }
   h2.home__statement {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 180%;
      @include mixins.sm {
         font-size: 1.75rem;
      }
   }
}
