@use 'variables';

@mixin border($color){
   border: 1px solid $color;
}

@mixin btn {
    padding: 1rem 1.2rem;
    text-decoration: none;
    border-radius: 3rem;
}

@mixin sm {
   @media (min-width: variables.$screen-sm-min) {
       @content;
   }
}

@mixin md {
   @media (min-width: variables.$screen-md-min) {
       @content;
   }
}

@mixin lg {
   @media (min-width: variables.$screen-lg-min) {
       @content;
   }
}

@mixin xl {
   @media (min-width: variables.$screen-xl-min) {
       @content;
   }
}

@mixin xxl {
   @media (min-width: variables.$screen-xxl-min) {
       @content;
   }
}