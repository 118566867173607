@use "../variables.scss";
@use "../mixins.scss";

.projects {
   &__grid {
      display: flex;
      gap: 64px 24px;
      flex-wrap: wrap;
      margin: 56px 0 100px;
      > a {
         width: 100%;
         @include mixins.md {
            width: calc(50% - 24px);
         }
      }
   }
}

.project {
   &__details {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include mixins.md {
         gap: 6rem;
      }
   }

   &__full {
      .project-item {
         &.video {
            display: flex;
            justify-content: center;
            padding: 2rem 0;
            @include mixins.md {
               padding: 4rem 0;
            }
         }
         img {
            width: 100%;
            height: auto;
         }

         video {
            border-radius: 12px;
            width: 90%;
            height: auto;
            @include mixins.md {
               border-radius: 20px;
               width: 80%;
            }
         }
      }
   }

   &__grid {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include mixins.md {
         flex-direction: row;
         gap: 2.5rem;
      }
   }

   &__col-left {
      grid-area: colLeft;
   }

   &__col-right {
      grid-area: colRight;
   }

   &__footer {
      display: flex;
      justify-content: center;
      margin: 1rem 0 4rem;
   }
}
