@use "../variables.scss";
@use "../mixins.scss";

.headline {
   display: flex;
   flex-direction: column;
   gap: 24px;
   align-items: flex-start;
   @include mixins.md {
      gap: 28px;
   }

   &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;
   }

   &__title {
      line-height: 100%;
      font-size: 2.75rem;
      font-weight: 600;
      @include mixins.md {
         font-size: 3.5rem;
      }
   }

   &__subtitle {
      font-size: 1.125rem;
      line-height: 180%;
   }
}

.project .headline {
   @include mixins.md {
      padding: 2rem 0 3rem;
   }
}
