$font-path: "../fonts/euclid-flex";

@font-face {
    src: url($font-path + "/EuclidFlex-Light.woff") format("woff");

    font-family: "Euclid Flex";
    font-weight: 300;
}

@font-face {
    src: url($font-path + "/EuclidFlex-Regular.woff") format("woff");

    font-family: "Euclid Flex";
    font-weight: 400;
}

@font-face {
   src: url($font-path + "/EuclidFlex-Medium.woff") format("woff");

    font-family: "Euclid Flex";
    font-weight: 500;
}

@font-face {
   src: url($font-path + "/EuclidFlex-SemiBold.woff") format("woff");

    font-family: "Euclid Flex";
    font-weight: 600;
}

@font-face {
   src: url($font-path + "/EuclidFlex-Bold.woff") format("woff");

    font-family: "Euclid Flex";
    font-weight: 700;
}