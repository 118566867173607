@use "../variables";
@use "../mixins";

.menu {
   align-items: center;
   background-color: variables.$color-neutral-high-base;
   color: variables.$color-neutral-low-base;
   display: flex;
   height: 100vh;
   justify-content: center;
   pointer-events: none;
   position: fixed;
   right: 0;
   top: 0;
   transform: translate3d(calc(-100% * -1), 0, 0);
   transition: transform 0.2s ease-out;
   width: 100vw;
   z-index: 2;
   @include mixins.md {
      width: 50vw;
   }
   &__wrap {
      top: 0;
      position: absolute;
   }
   &__backdrop {
      opacity: 0;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
      background: variables.$color-neutral-low-base;
      transition: opacity 0.2s ease-out;
   }
   &__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 80px;
      margin: 0 auto;
      text-align: center;
      width: 960px;
   }
   &__nav {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 36px;
      list-style: none;
   }

   &__link {
      align-items: flex-end;
      color: variables.$color-neutral-low-base;
      display: flex;
      font-size: 3.125rem;
      font-weight: 500;
      line-height: 47%;
      position: relative;
      &::before {
         content: "";
         background-color: variables.$color-brand-primary-base;
         display: block;
         height: 12px;
         opacity: 0.8;
         position: absolute;
         transition: 0.15s ease-in-out;
         width: 0%;
         z-index: -1;
      }
      &:hover {
         &::before {
            width: 100%;
         }
      }
   }

   &__contact {
      display: flex;
      flex-direction: column;
      gap: 4px;
      p {
         font-size: 1.125rem;
      }
   }
}
.inverse {
   .menu {
      &__backdrop {
         background: variables.$color-neutral-high-medium;
      }
   }
}
.show__menu {
   .menu {
      pointer-events: auto;
      opacity: 1;
      transform: translate3d(0, 0, 0);

      &__backdrop {
         pointer-events: auto;
         opacity: 0.9;
      }
   }
}
