@use "../mixins";

.page-content {
   &__title {
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
   }

   p {
      margin-bottom: 1rem;
      &.larger {
         font-size: 1.125rem;
         line-height: 200%;
      }
   }

   ul {
      padding-left: 16px;
      li {
         margin-bottom: 0.2rem;
      }
      &.no-bullet {
         padding-left: 0;
         li {
            list-style: none;
            margin-bottom: 4px;
         }
      }
   }
}

.home {
   &__content {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 3rem;
   }
}

.about,
.project {
   &__content {
      display: flex;
      flex-direction: column;
      gap: 3rem;
   }
   &__image {
      aspect-ratio: 16 / 9;
      overflow: hidden;
      position: relative;
      img {
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         object-fit: cover;
         object-position: center center;
      }
   }
   &__infos {
      @include mixins.md {
         display: grid;
         grid-template-areas: "overview offset details";
         grid-column-gap: 24px;
         margin: 30px 0 20px;
      }
   }
   &__links {
      margin-top: 32px;
      display: flex;
      gap: 2rem;
      margin-bottom: 2rem;
      @include mixins.md {
         margin-bottom: 0rem;
      }
   }
}

.project {
   &__infos {
      grid-template-columns: 6fr 1fr 3fr;
   }
}

.about {
   &__infos {
      grid-template-columns: 7fr 1fr 2fr;
   }
}

.offset {
   grid-area: offset;
}

.overview {
   grid-area: overview;
}

.details {
   grid-area: details;
}
