$font-stack: "Euclid Flex";

$color-brand-primary-base: #ff6171;
$color-brand-primary-light: #ffdfe3;
$color-brand-primary-medium: #f25c6b;
$color-brand-primary-dark: #bf4955;

$color-neutral-low-base: #030303;
$color-neutral-low-light: #a3a3a3;
$color-neutral-low-medium: #666666;
$color-neutral-low-dark: #292929;

$color-neutral-high-base: #fff;
$color-neutral-high-light: #f5f5f5;
$color-neutral-high-medium: #e0e0e0;
$color-neutral-high-dark: #cccccc;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1400px;

$grid-gutter: 16px;
$grid-columns: 12;
$grid-max: 1004px;
