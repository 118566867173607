@use "../variables";
@use "../mixins";

.header {
   padding: 2rem 0;
   position: relative;

   @include mixins.md {
      padding: 3.5rem 0;
   }

   &__logo {
      z-index: 22;
   }
   &__container {
      display: flex;
      justify-content: space-between;
   }

   &__menu {
      background: none;
      border: none;
      cursor: pointer;
      height: 24px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.15s ease-in-out;
      width: 24px;
      z-index: 22;

      span {
         display: block;
         position: absolute;
         height: 2px;
         width: 100%;
         background: variables.$color-neutral-high-base;
         border-radius: 9px;
         opacity: 1;
         left: 0;
         transform: rotate(0deg);
         transition: 0.15s ease-in-out;

         &:nth-child(1) {
            top: 12px;
         }

         &:nth-child(2) {
            top: 20px;
         }
      }
   }
}

.show__menu {
   .header {
      &__menu {
         span {
            background-color: variables.$color-neutral-low-base;
            &:nth-child(1) {
               top: 16px;
               transform: rotate(45deg);
            }
            &:nth-child(2) {
               top: 16px;
               transform: rotate(-45deg);
            }
         }
      }
   }
}

.inverse,
.inverse.show__menu,
.default.show__menu {
   .header {
      &__menu {
         span {
            background: variables.$color-neutral-low-base;
         }
      }
   }
}
